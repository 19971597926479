function upload(id,params) {
    return new Promise(function(resolve,reject){
        fetch(`/user/upload?id=${id}`,{
            method: 'POST',
            // headers:{
            //     "Content-Type":"‌multipart/form-data"
            // },
            body:params
        })
        .then(response => response.json())
        .then(data => {
            console.log(data)
            if(data.isSuccess){
                resolve(data.data)
            }else{
                reject(data.errMsg)
            }
        })
        .catch(error => reject(error));
    })
}
export default upload;

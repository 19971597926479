import styles from './index.module.css';
import Config from '../../config';
function Dialog({ message, onConfirm }) {
  return (
    <div className={styles.dialog}>
      <div className={styles.mask}></div>
      <div className={styles.con}>
      <div className="pop_wrap">
          <div className="pop_h"></div>
          <div className="pop_c">

            <div className={styles.text} dangerouslySetInnerHTML={{__html:message}}></div>
            <div className={styles.h40}></div>
            <div className={styles.btn_wrap} onClick={onConfirm}>
              <div className={styles.btn_con}>
                <img src={`${Config.ossDoMain}/images/t_queren.webp`} alt="确认"/>
              </div>
            </div>
            <div className={styles.h40}></div>

          </div>
          <div className="pop_f"></div>
      </div>
        
      </div>
    </div>
  );
}

export default Dialog;

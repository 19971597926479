import styles from './index.module.css';
import Config from '../../config';
function NavRule({onClick}) {
  return (
    <div className={styles.nav_rule} onClick={onClick}>
      <img src={`${Config.ossDoMain}/images/nav_rule.webp`} alt="查看活动规则"/>
    </div>
  );
}

export default NavRule;


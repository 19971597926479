import styles from './index.module.css';
function CloseBtn({onClick}) {
  return (
    <div className={styles.close_btn} onClick={onClick}>
      <div className={styles.box}>
        <div className={styles.line}></div>
        <div className={styles.line}></div>
      </div>
    </div>
  );
}

export default CloseBtn;

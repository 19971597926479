function getOpportunitys(params) {
    return new Promise(function(resolve,reject){
        fetch(`/user/getOpportunitys`,{
            method: 'POST',
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify(params)
        })
        .then(response => response.json())
        .then(data => {
            console.log(data)
            if(data.isSuccess){
                resolve(data.data||0)
            }else{
                reject(data.errMsg||'请求失败') 
            }
        })
        .catch(error => reject(error));
    })
}
export default getOpportunitys;

import styles from './index.module.css';
import Config from '../../config';
function NavCard({onClick}) {
  return (
    <div className={styles.nav_card} onClick={onClick}>
      <img src={`${Config.ossDoMain}/images/nav_card.webp`} alt="查看我的卡券"/>
    </div>
  );
}

export default NavCard;


import {useCallback, useEffect, useState} from 'react';
import { useNavigate } from "react-router-dom";

import MyCard from '../../components/my_card';
import EditInfo from '../../components/edit_info';
import WinningList from '../../components/winning_list';

import Util from '../../utils';
import Config from '../../config';

import getPrizeCards from "../../request/getPrizeCards";

import getQrCode from "../../request/getQrCode";
import getWinPrizeList from "../../request/getWinPrizeList";
import upload from "../../request/upload";
import BtnMax from '../../components/btn_max';
function Card() {

  const backgroundStyle = {
    backgroundImage: `url(${Config.ossDoMain}/images/bg.webp)`,
    backgroundSize: 'cover',
  };
  const [visible, setVisible] = useState(false)
  const [openStatus, setOpenStatus] = useState(false)
  
  const [listData, setListData] = useState(null)
  const [visibleEditInfo, setVisibleEditInfo] = useState(false)
  const [qrcode, setQrcode] = useState('')
  const [infoLevel, setInfoLevel] = useState(1)

  const [vinList, setVinList] = useState({})
  const [visibleWinningList, setVisibleWinningList] = useState(false)
  
  let navigate = useNavigate();

  // 支付宝需上传换取url地址
  const onAlipayUpload = async (id,data) => {
    const param = {
      id,
      imgData:data,
    }
    const blob = Util.base64ToBlob(data);
    const formData = new FormData();
    formData.append('file', blob, 'poster.png');

    return new Promise(function(resolve){
      upload(param.id,formData).then(res=>{
        console.log("upload:",res)
        const _url = Config.origin+res
        resolve(_url)
      }).catch(err=>{
        console.log(err)
        window._alert(err)
      })
    })
  }

  const drawCard = useCallback(async (lotNo) => {
    let param = {
      with:725,
      height: 1107,
      bg:`/images/p_card.webp`,
      mask:`/images/p_mask.webp`,
      qrcode:qrcode,
      s_num:lotNo?`幸运号码：${lotNo}`:''
    }
    console.log("param",param)
    const _data = await Util.poster(param)
    return !_data?'':_data
  },[qrcode])
  const drawPrizeCard = useCallback(async (lotNo,level) => {
    let param = {
      with:725,
      height: 1107,
      bg:`/images/p_card_2.webp`,
      prizePic:`/images/p_prize_pic_${level}.webp`,
      mask:`/images/p_mask.webp`,
      qrcode:qrcode,
      s_num:lotNo?`幸运号码：${lotNo}`:''
    }
    const _data = await Util.prizePoster(param)
    return !_data?'':_data
  },[qrcode])

  // 获取中间结果数据
  // status 0 未中奖，1 中奖
  const getListData = useCallback(async (pc_data) => {
    console.log("pc_data",pc_data,pc_data.length<1)
    if(pc_data.length<1) return

    let _openStatus = localStorage.getItem('has_winning')==='1'?true:false
    // 已开奖需要排序
    if(_openStatus){
      pc_data.sort((a, b) => b.level - a.level);
    }
    let data = []
    for(let item of pc_data){
      let src = ''
      let url = ''
      console.log("item",item)
      if(_openStatus && item.status){ // 中奖了的，需要重回
        src = await drawPrizeCard(item.cardNo, item.level)
        // 支付宝需上传换取url地址
        if(Util.getChannelCode()==='ALIPAY' && !url){
          url = await onAlipayUpload(item.id, src)
        }
      }else{
        if(item.cardNo && localStorage.getItem(item.cardNo)){
          src = localStorage.getItem(item.cardNo)
          console.log("海报图走的缓存")
          if(Util.getChannelCode()==='ALIPAY' && item.cardNo && localStorage.getItem(item.cardNo+'_url')){
            url = localStorage.getItem(item.cardNo+'_url')
          }
        }else{
          // 支付宝需上传换取url地址
          if(Util.getChannelCode()==='ALIPAY' && item.sharePicPath){
            src = Config.origin+item.sharePicPath
            url = Config.origin+item.sharePicPath
          }else{
            src = item.status ? await drawPrizeCard(item.cardNo, item.level) : await drawCard(item.cardNo);
          }
          console.log("海报图走的生成")
          // 支付宝需上传换取url地址
          if(Util.getChannelCode()==='ALIPAY' && !url){
          url = await onAlipayUpload(item.id, src)
          }
        }
      }
      data.push({
        src:src,
        url:url,
        status: _openStatus?(item.status?(item.mobile?2:1):0):-1,
        hasInfo: (item.name && item.address)? true : false,
        level: item.level
      })
      // 实在海报图缓存
      try{
        item.cardNo && src && localStorage.setItem(item.cardNo,src)
        item.cardNo && url && localStorage.setItem(item.cardNo+'_url',url)
      }catch(err){
        console.log(err)
      }
    }
    console.log('listData::')
    console.log(data)
    setListData(data)
    setVisible(true)
    setOpenStatus(_openStatus)
  },[drawCard, drawPrizeCard])
  // 关闭我的卡券面板
  const onCloseMyCard = useCallback((e) => {
    setVisible(false)
    if(e && typeof e==="string"){
      window._alert(e) 
    }
  },[])

  // 前往送祝福
  const onGoSend = useCallback(() => {
    setVisible(false)
    navigate('/send')
  },[navigate])

  const onGoTask = useCallback(() => {
    navigate('/task');
  },[navigate])
  // 前往填写信息
  const onGoInfo = useCallback((level) => {
    //navigate('/info')
    console.log("level:",level)
    setInfoLevel(level)
    setVisibleEditInfo(true)
    onCloseMyCard()
  },[onCloseMyCard])

  // 提交信息之后的回调
  const onCallBack = () => {
    setVisibleEditInfo(false)
  }

  

  // 获取中奖信息
  const onGetPrizeCards = useCallback(() => {
    const param = {}
    
    getPrizeCards(param).then(res=>{
      const prizeCards = res
      console.log("prizeCards",prizeCards)
      // setCardList(prizeCards)
      if(prizeCards.length<1) {
        setListData([])
        return
      }
      getListData(prizeCards)
      // setPrizes(prizes)
    }).catch(err=>{
      window._alert(err)
    })
  },[getListData])

  let retryCount = 0
  const onQrCode = useCallback(() => {
    const param = {
      channelCode:Util.getChannelCode()
    }
    const _url = localStorage.getItem(`${param.channelCode}_qrcode_url`)
    console.log("获取缓存",_url)
    if(_url){
      setQrcode(_url)
      return
    }
    getQrCode(param).then(res=>{
      console.log("getQrCode:",res)
      setQrcode(res)
      try{
        localStorage.setItem(`${param.channelCode}_qrcode_url`,res)
      }catch(err){

      }
    }).catch(err=>{
      if(retryCount<2){
        retryCount++;
        onQrCode()
      }else{
        setQrcode(`/images/${param.channelCode}_qrcode.webp`)
      }
      console.log(err)
    })
  },[])

  const onGetWinPrizeList = useCallback(() => {
    getWinPrizeList().then(res=>{
      console.log("count",res)
      if(res && Object.keys(res).length>0){
        try{
          localStorage.setItem('has_winning',1)
        }catch(err){
          console.log(err)
        }
      }else{
        try{
          localStorage.setItem('has_winning',0)
        }catch(err){
          console.log(err)
        }
      }
      let v_data = {}
      if(res){
        for(let key in res){
          v_data['level_'+key] = res[key]
        }
      }
      setVinList(v_data)
      setVisibleWinningList(true)
     
    }).catch(err=>{
      console.log(err)
    })
  },[])

  const onHideWinningList = useCallback(()=>{
    setVisibleWinningList(false)
  },[])

  useEffect(()=>{
    onQrCode()
  },[onQrCode])
  useEffect(()=>{
    if(!qrcode) return
    onGetPrizeCards()
  },[onGetPrizeCards, qrcode])
  return (
    <>
    <div className="Card page_container" style={backgroundStyle}>
      <img className="s_title" src={`${Config.ossDoMain}/images/c_title.webp`} alt=""/>
      <div className="c_container">
        <div className="m_card_wrap">
        {listData && listData.length>0 && <MyCard listData={listData} onGoInfo={onGoInfo} onGoSend={onGoSend} onClose={onCloseMyCard}></MyCard>}
        {listData && listData.length<1 &&<div className="empty_con flex_h_center">
          <div className="m_tip">您还没收获到您的好运卡哦！</div>
          <div className="h38"></div>
          <div className="m_btn_wrap">
          <BtnMax onClick={onGoTask} _style={{width:'528px'}}>
            返回任务得更多抽奖机会
          </BtnMax>
          </div>
        </div>}
        </div>
        
        {listData && listData.length>0 && <div className={openStatus?"c_tip open":"c_tip"}>
          {openStatus && <BtnMax onClick={onGetWinPrizeList}>
            查看中奖卡号
          </BtnMax>}
          <img className="snake" src={`${Config.ossDoMain}/images/snake.webp`} alt=""/>
          <div className="h20"></div>
          <img className="r_text" src={`${Config.ossDoMain}/images/r_text.webp`} alt=""/>
        </div>
        }
        
      </div>
      <img className="g_b" src={`${Config.ossDoMain}/images/g_b.webp`} alt=""/>
    </div>
    <EditInfo visible={visibleEditInfo} level={infoLevel} onCallBack={onCallBack}></EditInfo>
    <WinningList visible={visibleWinningList} vinList={vinList} onClick={onHideWinningList}></WinningList>
    </>
  )
}
export default Card;
let _doMain = ''
if(window.location.hostname==='localhost'){
    _doMain = 'ae.z3z2.com'
}else{
    _doMain = window.location.hostname
}
const Config = {
    bindCardUrl:'/pages/user/cards/firstStep/index',
    doMain:`https://${_doMain}/`,
    origin:`https://${_doMain}`,
    // ossDoMain:'http://192.168.1.15/maccan/amex0115_images'
    ossDoMain:'',
    discountUrl:'/pages/benefits/detail/index'
}
export default Config
import Util from "../utils";
function getPrizeCards(params) {
    // const urlParams = Util.objectToUrlParams(params);
    // const pStr = urlParams.length>0?`?${urlParams}`:''
    return new Promise(function(resolve,reject){
        fetch(`/user/getPrizeCards`,{
            method: 'POST',
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify(params)
        })
        .then(response => response.json())
        .then(data => {
            console.log(data)
            if(data.isSuccess){
                resolve(data.data)
            }else{
                reject(data.errMsg||'请求失败') 
            }
        })
        .catch(error => reject(error));
    })
}
export default getPrizeCards;

import styles from './index.module.css';
function BtnMax({onClick,_style,children}) {
   
    return (
      <div className={styles.btn_max} style={_style} onClick={onClick}>
        <div className={styles.btn_wrap}>
        {children}
        </div>
      </div>
    );
  }
  
  export default BtnMax;
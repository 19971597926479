import CloseBtn from '../close_btn';
import styles from './index.module.css';
import Config from '../../config';
function Rule({visible,onClick}) {
    
    return (
        <>
        {visible &&    
        <div className={styles.rule}>
            <div className={styles.mask}></div>
            <div className={styles.con}>
            <img className={styles.title} src={`${Config.ossDoMain}/images/rule_title.webp`} alt=""/>
            <div className="h38"></div>
            <div className="pop_wrap">
                <div className="pop_h"></div>
                <div className="pop_c">
                    <div className="h38"></div>
                    <div className={styles.scroll}>
                        <img src={`${Config.ossDoMain}/images/rule_text_2.webp`} alt=""/>
                    </div>
                    <div className="h38"></div>
                </div>
                <div className="pop_f"></div>
            </div>
            </div>
            <div className={styles.close} onClick={onClick}>
                <CloseBtn></CloseBtn>
            </div>
        </div>
        }
        </> 
    );
  }
  
  export default Rule;
  
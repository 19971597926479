import {useEffect, useState} from 'react';
import BtnMax from '../btn_max';
import Config from '../../config';
import { Navigation} from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

import styles from './index.module.css';
function PreCard({listData, onGoSend, onClose}) {
    const [cardList, setCardList] = useState([])
    const [initialSlide] = useState(0)
    const [loaded, setLoaded] = useState(false)

    const onImgLoaded = () => {
       setLoaded(true)
    }
    useEffect(()=>{
        setCardList(listData)
    },[listData])
    return (
    <div className={styles.card_wrap}>
        <div className={styles.mask}></div>
        <div className={styles.swiper_wrap}>
        <Swiper
        modules={[Navigation]}
        slidesPerView={1} 
        spaceBetween={20}
        navigation
        initialSlide={initialSlide}
        >
        {cardList.map((item,index) =>    
        <SwiperSlide key={index}>

            <div className={styles.imgbox}>
                {!loaded && <div className='loadingAn'></div>}
                <img src={item.src} alt="" onLoad={onImgLoaded}/>
            </div>
            {item.url && <img className={styles.url} src={item.url} alt=""/>}
            
        </SwiperSlide>
        )}
        </Swiper>
        </div>
        
        <div className={styles.p_c_box}>
            <BtnMax onClick={onGoSend}>
                <img src={`${Config.ossDoMain}/images/t_sgdzf.webp`} alt="关闭" onClick={onClose}/>
            </BtnMax>
        </div>
    </div>
    );
  }
  
  export default PreCard;
  
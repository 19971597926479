import { useEffect } from 'react'
import Util from '../../utils';
function Loading() {
  const loadArr = [
    '/images/bg.webp',
    '/images/p_card.webp',
    '/images/g_bg.webp',
    '/images/p_mask.webp'
  ]
  
    useEffect(()=>{
      for(let item of loadArr){
        Util.loadImage(item)
      }
    },[])
    return (
      <></>
    );
  }
  
  export default Loading;
// import BtnMax from '../btn_max';
import {useState} from 'react';
import CloseBtn from '../close_btn';
import styles from './index.module.css';
function Share({visible,src,url='',onClick}) {

    const [loaded, setLoaded] = useState(false)

    const onImgLoaded = () => {
       setLoaded(true)
    }
    return (
        <>
        {visible &&    
        <div className={styles.share}>
            <div className={styles.mask}></div>
            <div className={styles.con}>
                <div className={styles.imgbox}>
                    {!loaded && <div className='loadingAn'></div>}
                    <img src={src} alt="" onLoad={onImgLoaded}/>
                </div>
                {url && <img className={styles.url} src={url} alt=""/>}
                <div className={styles.h44}></div>
                <div className={styles.c_box} onClick={onClick}>
                    <CloseBtn></CloseBtn>
                </div>
            </div>
        </div>
        }
        </> 
    );
  }
  
  export default Share;
  
// game.js
import Config from "../../config"
function loadImage(src) {
  return new Promise(function (resolve, reject) {
      let img = new Image()
      img.crossOrigin = 'anonymous'
      img.onload = function(){
          resolve(img)
      }
      img.src = src
  })
}

function game(options){
    console.log('2')
    const self = this
    this.canvas = null
    this.ctx = null
    this.i_snake = null
    this.i_jb = null
    this.i_qq = null
    this.i_tq = null
    this.i_card = null
    this.m_hy = null
    this.m_hl = null
    this.timer = null
    this.animationId = null
    this.itemSpeed = 15
    this.hasCard = false
    this.reuse = false
    this.x_ran = -1
    this.items = []
    console.log('4')
    this.isDragging = false
    this.startX = null
    this.startSnakeX = null
    this.prize_count = 0
    console.log('5')
    this.duration = options.duration
    console.log('6')
    this.milliseconds‌ = options.milliseconds‌
    console.log('7')
    this.steps = 1000*(options.duration-1) / options.milliseconds‌
    console.log('8')
    this.rIndex = []
    this.current = 0
    console.log('10')
    this.card_count = options.card_count||1
    

    this.itemTypes = [{
        w:101,
        h:101,
        type:1,
        src:()=>{
          return this.i_jb
        }
      },{
        w:101,
        h:101,
        type:1,
        src:()=>{
          return this.i_qq
        }
      },{
        w:137,
        h:137,
        type:1,
        src:()=>{
          return this.i_tq
        }
      },{
        w:147,
        h:225,
        type:2,
        src:()=>{
          return this.i_card
        }
      }];
    
    let _r = Math.floor(Math.random()*3)
    this.material = [
      { name:'i_snake', value:'i_snake.webp' },
      { name:'i_jb', value:'i_jb.webp' },
      { name:'i_qq', value:'i_qq.webp' },
      { name:'i_tq', value:'i_tq.webp' },
      { name:'i_card', value:`i_card_${_r}.webp` },
      { name:'m_hy', value:'m_hy.webp' },
      { name:'m_hl', value:'m_hl.webp' }
    ]

    // 蛇
    this.snake = {
        x: 902 / 2 - 108,
        y: 1277 - 360,
        width: 215,
        height: 360,
        draw() {
            self.ctx.globalAlpha = 1
            self.ctx.drawImage(self.i_snake,0,0,self.i_snake.width, self.i_snake.height,this.x, this.y, this.width, this.height)
        }
    };
    // 碰撞检测
    this.checkCollision = function(item) {
        if(item.type==='hl'||item.type==='hy') return false
        // 区域缩写
        return (
        item.x < this.snake.x + 2*this.snake.width/3 &&
        item.x + item.width > this.snake.x + this.snake.width/3 &&
        item.y < this.snake.y + this.snake.height/3 &&
        item.y + item.height > this.snake.y
        );
    }

    this.getTX = function(type){
        for(let item of this.items){
          if(item.opcity===0 && item.type===type){
            return item
          }
        }
        return null
    }

    this.createTX = function(type,x,y) {
        console.log(type,type===1)
        const _t = type===1?'hy':'hl'
        const _img = type===1?this.m_hy:this.m_hl
        // 查看是否有透明度为0的元素
        let _item = this.getTX(_t)
        if(_item){
          console.log("有可以复用的")
          _item.x = x
          _item.y = y
          _item.opcity = 1
        }else{
          const item = {
            x: x,
            y: y,
            width: 178,
            height: 117,
            type: _t,
            opcity:1,
            draw() {
              self.ctx.globalAlpha = this.opcity
              self.ctx.drawImage(_img,0,0,_img.width, _img.height,this.x, this.y - this.height/2, this.width, this.height)
            },
            update() {
              this.opcity -= 0.02;
              if(this.opcity<=0) this.opcity = 0
            }
          };
          self.items.push(item);
        }
      }


    this.randomIndex = function() {
        let _xi = Math.floor(Math.random() * 6)
        if(_xi === this.x_ran){
          _xi = _xi+1
          if(_xi>=6){
            _xi = 0
          }
        }
        this.x_ran = _xi
        return _xi
    }
    this.calculate_x = function() {
        let _ran = this.randomIndex()
        let x = _ran*150
        console.log("随机的",_ran)
        if(x<60){
          x = 60
        }
        return x
    }

    this.getRIndexArr = function() {

      do {
        let _ri = Math.floor(Math.random()*this.steps)
        this.rIndex.push(_ri)
      } while (this.rIndex.length<this.card_count);
      
    }
    

    this.init = async function(){
        console.log("执行力吗 ")
        
        for(let item of this.material){
            this[item.name] = await loadImage(`${Config.ossDoMain}/images/${item.value}`)
        }
        console.log("加载完成")

       this.canvas = options.canvas;
       console.log("kokoko")
       
        this.canvas.addEventListener('touchstart', (e) => {
            this.isDragging = true;
            this.startX = e.touches[0].clientX;
            this.startSnakeX =this.snake.x;
        });
          
        this.canvas.addEventListener('touchmove', (e) => {
            let offset = 0 // 偏移量
            if (this.isDragging) {
              const dx = e.touches[0].clientX - this.startX;
              if(this.startSnakeX + dx < - offset || this.startSnakeX + dx > this.canvas.width - this.snake.width + offset){
              }else{
                this.snake.x = this.startSnakeX + dx;
              }
            }
          });
          
        this.canvas.addEventListener('touchend', () => {
            this.isDragging = false;
        });


        this.ctx = this.canvas.getContext('2d');
        if(options && typeof options.currentTime == 'function'){
            this['currentTime'] = options.currentTime
        }
        if(options && typeof options.gameOver == 'function'){
            this['gameOver'] = options.gameOver
        }

        // 随机出card_count张卡片位置
        this.getRIndexArr()
        console.log("随机到的数字是",this.rIndex)
        this.start()
    }

    this.start = function (){
        if(this.timer){
            clearInterval(this.timer)
        }
        this.timer = setInterval(this.createItem, this.milliseconds‌);
        let _timer = setInterval(()=>{
            this.duration -= 1
            this.currentTime(this.duration)
            if(this.duration<=0){
              // 游戏结束
              cancelAnimationFrame(this.animationId)
              clearInterval(_timer)
              //   alert('游戏结束')
              let count = this.prize_count
              this.gameOver(count)
              clearInterval(this.timer)
            }
        }, 1000);

        this.gameLoop()
    }

    this.createItem = () => {
        let t = false
        
        this.current += 1
        console.log("第几个位置创建卡片",this.current,this.rIndex)
        if(this.rIndex.includes(this.current)){
          t = true
        }
        // if(this.items.length>3 && !this.hasCard){
        //   t = true
        // }
        if(this.reuse && !t) return
        // const x_ran = Math.floor(Math.random() * (itemTypes.length-1))
        const _random = t?this.itemTypes.length-1: Math.floor(Math.random() * (this.itemTypes.length-1))
        const itemType = this.itemTypes[_random];
        const scale = (itemType.type!==2 && Math.random()<0.5)?0.7:1
        const width = itemType.w*scale
        const height = itemType.h*scale
        const self = this
        const item = {
          x: self.calculate_x(),
          y: -itemType.h*scale,
          width,
          height,
          type: itemType.type,
          opcity:1,
          draw() {
            const _img = itemType.src()
            self.ctx.globalAlpha = 1
            self.ctx.drawImage(_img,0,0, _img.width, _img.height,this.x, this.y, this.width, this.height)
          },
          update() {
            this.y += self.itemSpeed;
          }
        };
        self.items.push(item);
        if(t){
          self.hasCard = true
        }
    }

    // 游戏循环
    this.gameLoop = () => {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    // 更新和绘制物件
    this.items.forEach((item, index) => {
      item.update();
      item.draw();
  
      // 检测碰撞
      if (this.checkCollision(item)) {
        // score += itemTypeScores[item.color];
        console.log("触碰",item)
        this.createTX(item.type,item.x,item.y)
  
        if(item.type===2){
          this.items.splice(index, 1);
          this.prize_count +=1
        }else{
          item.x = this.calculate_x()
          item.y = -item.height
          console.log("复用了一个")
        }
        this.reuse = true
      }
  
      // 移除掉出屏幕的物件
      if (item.y > this.canvas.height) {
  
        if(item.type===2){
          this.items.splice(index, 1);
        }else{
          item.x = this.calculate_x()
          item.y = -item.height
          console.log("复用了一个")
        }
        this.reuse = true
      }
    });
  
    // 绘制盘子
    this.snake.draw();
    // 绘制积分
    // drawScore();
  
    // 请求下一帧
    this.animationId = requestAnimationFrame(this.gameLoop);
  }

  this.init()
}
export default game
import { useCallback, useEffect, useState} from 'react'
import { useNavigate } from "react-router-dom";

import getQrCode from "../../request/getQrCode";
import getWinPrizeList from "../../request/getWinPrizeList";

import NavCard from '../../components/nav_card';
import Tip from '../../components/tip';

import Util from '../../utils';
import Config from '../../config';

function Home({logon}) {
    const [visibleTipDetail, setVisibleTipDetail] = useState(false)
    const [qrcode, setQrcode] = useState('')
    
    const backgroundStyle = {
      backgroundImage: `url(${Config.ossDoMain}/images/bg.webp)`,
      backgroundSize: 'cover',
    };
    const [src] = useState('/images/tip.webp')
    let navigate = useNavigate();
    const isMiniprogram = localStorage.getItem('miniprogram')
    const onGoSend = useCallback(() => {
        navigate('/send');
    },[])

    const onGoDiscount = useCallback(() => {
      // const backUrl = encodeURIComponent(Config.doMain)
      // const code = window.AMEX_ALLPARAMS.code
      console.log("跳转地址",`${Config.discountUrl}?homepageNo=BHP628619725497004032&benefitNo=B628361989723099136&utm_source=2010&utm_medium=公众号签名&utm_content=立减金&utm_campaign=绑卡抽立减金&_channel_track_key=s6it2ZgW`)
      // eslint-disable-next-line no-undef
      if(isMiniprogram==="1" && !!wx){
        // eslint-disable-next-line no-undef
        wx.miniProgram.navigateTo({
            url:`${Config.discountUrl}?homepageNo=BHP628619725497004032&benefitNo=B628361989723099136&utm_source=2010&utm_medium=公众号签名&utm_content=立减金&utm_campaign=绑卡抽立减金&_channel_track_key=s6it2ZgW`
        });
      }
      // eslint-disable-next-line no-undef
      if(!!my){
        // eslint-disable-next-line no-undef
        my.navigateTo({
            url:`${Config.discountUrl}?homepageNo=BHP628619725497004032&benefitNo=B628361989723099136&utm_source=2010&utm_medium=公众号签名&utm_content=立减金&utm_campaign=绑卡抽立减金&_channel_track_key=s6it2ZgW`
        });
      }
    },[])
    // const onShowCard = useCallback(() => {
    //     navigate('/card');
    // },[])

    const onGetWinPrizeList = useCallback(() => {
      getWinPrizeList().then(res=>{
        console.log("count",res)
        if(res && Object.keys(res).length>0){
          try{
            localStorage.setItem('has_winning',1)
          }catch(err){
            console.log(err)
          }
        }else{
          try{
            localStorage.setItem('has_winning',0)
          }catch(err){
            console.log(err)
          }
        }
       
      }).catch(err=>{
        console.log(err)
      })
    },[])

    let retryCount = 0
    const onQrCode = useCallback(() => {
      const param = {
        channelCode:Util.getChannelCode()
      }
      const _url = localStorage.getItem(`${param.channelCode}_qrcode_url`)
      if(_url){
        setQrcode(_url)
        return
      }
      getQrCode(param).then(res=>{
        console.log("getQrCode:",res)
        setQrcode(res)
        try{
          localStorage.setItem(`${param.channelCode}_qrcode_url`,res)
        }catch(err){
  
        }
      }).catch(err=>{
        if(retryCount<2){
          retryCount++;
          onQrCode()
        }else{
          setQrcode(`/images/${param.channelCode}_qrcode.webp`)
        }
        console.log(err)
      })
    },[])
  
   
    const onHideTipDetail = useCallback(() => {
      setVisibleTipDetail(false)
      onGoSend()
      console.log("ok!")
    },[])

    useEffect(()=>{
      if(logon===true){
        console.log("执行了吗")
        onQrCode()
        onGetWinPrizeList()
      }
    },[logon, onQrCode])

    let _count = 0
    const onDebug = useCallback(() => {
      _count++;
      if(localStorage.getItem('_debug')==='1'){
        localStorage.setItem('_debug','0')
      }
      if(_count===19){
        localStorage.setItem('_debug','1')
      }
    },[_count])
    return (
      <>
      <div className="Home page_container" style={backgroundStyle}>
      <div className="h_container">
        <div className="h_item" onClick={onGoSend}>
          <img className="h_nav_bg" src={`${Config.ossDoMain}/images/h_nav_bg.webp`} alt="背景"/>
          <img className="h_t" src={`${Config.ossDoMain}/images/h_t_1.webp`} alt="通幸运"/>
          <div className="h_s_wrap">
            <img className="h_s" src={`${Config.ossDoMain}/images/h_s_1.webp`} alt="通吃新年大奖"/>
          </div>
          <img className="h_sw s_1" src={`${Config.ossDoMain}/images/shuiwen.webp`} alt="水纹"/>
        </div>
        <div className="h_item" onClick={onGoDiscount}>
          <img className="h_nav_bg" src={`${Config.ossDoMain}/images/h_nav_bg.webp`} alt="背景"/>
          <img className="h_t" src={`${Config.ossDoMain}/images/h_t_2.webp`} alt="通财运"/>
          <div className="h_s_wrap">
            <img className="h_s" src={`${Config.ossDoMain}/images/h_s_2.webp`} alt="领取微信立减金"/>
          </div>
          <img className="h_sw s_2" src={`${Config.ossDoMain}/images/shuiwen.webp`} alt="水纹"/>
        </div>
        {/* <div className="h_item">
          <img className="h_nav_bg" src={`${Config.ossDoMain}/images/h_nav_bg.webp`} alt="背景"/>
          <img className="h_t" src={`${Config.ossDoMain}/images/h_t_3.webp`} alt="通福运"/>
          <div className="h_s_wrap">
            <img className="h_s" src={`${Config.ossDoMain}/images/h_s_3.webp`} alt="领取红包封面"/>
          </div>
        </div> */}
        <img className="h_text" src={`${Config.ossDoMain}/images/h_text.webp`} alt="点击通幸运"/>
      </div>

      <img className="h_b" src={`${Config.ossDoMain}/images/h_b.webp`} alt="" onClick={onDebug}/>

      {/* <NavCard onClick={onShowCard}></NavCard> */}
      
      </div>
      <Tip visible={visibleTipDetail} src={src} onClick={onHideTipDetail}></Tip>
      </>
    )
}
    export default Home;